define("itarp-admin-dashboard/services/notifications", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "socket.client", "@ember/runloop"], function (_exports, _service, _tracking, _object, _socket, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NotificationsService = (_dec = (0, _service.inject)('ad-token-service'), _dec2 = (0, _service.inject)('router'), _dec3 = (0, _service.inject)('store'), (_class = class NotificationsService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "client", _descriptor4, this);

      _initializerDefineProperty(this, "observers", _descriptor5, this);

      _initializerDefineProperty(this, "messages", _descriptor6, this);

      _initializerDefineProperty(this, "unread", _descriptor7, this);
    }

    get urlLocation() {
      return this.router.location.location;
    }

    get host() {
      return this.urlLocation.host;
    }

    get protocol() {
      const secure = this.urlLocation.protocol.startsWith('https');
      return secure ? 'wss' : 'ws';
    }

    get wsUrl() {
      return `${this.protocol}://${this.host}/ws/socket`;
    }

    setupClient() {
      if (this.client) return;
      this.client = new _socket.SocketClient();
      this.client.setOnNotificationCallback(this.notificationReceived);
      this.client.setOnRegisterSuccessCallback(this.registrationSuccess);
      this.client.setOnRegisterErrorCallback(this.registrationError);
      this.client.registerUser(this.auth.token, this.wsUrl);
      this.getNotifications();
      this.getNotificationCount();
    }

    addNotificationObserver(callback) {
      (0, _runloop.next)(this, () => {
        this.observers.pushObject(callback);
        this.observers = this.observers.uniq();
      });
    }

    notificationReceived(response) {
      for (const observer of this.observers) {
        observer(response);
      }

      this.getNotificationCount();
    }

    registrationSuccess(response) {
      console.log('User has been successfully registered!');
    }

    registrationError(errors) {
      console.log('Registration has some errors: ');
      console.log(errors);
    }

    getNotificationCount() {
      const adapter = this.store.adapterFor('notification');
      const url = adapter.namespace + '/notifications/count';

      const query = id => `filter[recipient_model_name]=users&filter[recipient_unique_attributes.id]=${id}&filter[seen_at]=null`;

      try {
        fetch(`${url}?${query(this.auth.tokenInformation.sub)}`, {
          method: 'GET',
          headers: adapter.headers
        }).then(async response => await response.json()).then(result => {
          this.unread = result.data;
        });
      } catch (error) {
        console.log(error);
      }
    }

    getNotifications() {
      this.store.query('notification', {
        filter: {
          recipient_model_name: 'users',
          'recipient_unique_attributes.id': this.auth.tokenInformation.sub,
          seen_at: 'null'
        },
        per_page: 5,
        order_by: ['created_at', 'desc']
      }).then(data => {
        var _data$meta, _data$meta$page;

        this.messages = data.toArray();
        this.unread = (_data$meta = data.meta) === null || _data$meta === void 0 ? void 0 : (_data$meta$page = _data$meta.page) === null || _data$meta$page === void 0 ? void 0 : _data$meta$page.total;
      });
    }

    markRead() {
      const pending = this.messages.map(message => {
        message.set('seenAt', new Date());
        return message.save();
      });
      Promise.all(pending).then(() => this.getNotifications());
    }

    get unreadCount() {
      return this.unread ? this.unread : 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "client", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "observers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "messages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "unread", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupClient", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupClient"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addNotificationObserver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addNotificationObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notificationReceived", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "notificationReceived"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registrationSuccess", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registrationSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registrationError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registrationError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getNotificationCount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getNotificationCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getNotifications", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getNotifications"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markRead", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markRead"), _class.prototype)), _class));
  _exports.default = NotificationsService;
});