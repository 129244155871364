define("itarp-admin-dashboard/controllers/favorites", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/string"], function (_exports, _controller, _object, _tracking, _service, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FavoritesController = (_dec = (0, _service.inject)('ad-token-service'), (_class = class FavoritesController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _initializerDefineProperty(this, "expert", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "tab", _descriptor4, this);
    }

    showExpertPreview(expert) {
      this.store.findRecord('user', expert.id).then(user => {
        this.expert = user;
      });
    }

    hideExpertPreview() {
      this.expert = null;
      this.tab = null;
    }

    get selectedExpert() {
      if (this.expert) {
        const selected = this.favorites.findBy('id', this.expert);
        if (selected) return selected;
      }
    }

    get isShowingExpertPreview() {
      return this.expert !== null;
    }

    get expertPreviewConfig() {
      const expert = this.expert;
      return {
        expert,
        isOpen: this.isShowingExpertPreview,
        close: this.hideExpertPreview,
        activeTab: this.tab,
        selectTab: val => this.tab = val
      };
    }

    get userType() {
      const type = this.auth.tokenInformation.extension_user_type;
      if (!type) return '';
      return (0, _string.capitalize)(type.replaceAll('_', ' '));
    }

    imageLoadError(event) {
      event.target.src = '/itarp-shared-assets-addon/images/user_placeholder.png';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "expert", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showExpertPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showExpertPreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideExpertPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideExpertPreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "imageLoadError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "imageLoadError"), _class.prototype)), _class));
  _exports.default = FavoritesController;
});