define("itarp-admin-dashboard/utils/shared-event-bus", ["exports", "itarp-admin-dashboard/utils/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SharedEventBus {
    static addEventHandler(topicName, callback) {
      (0, _helpers.addEventHandler)(this.subscriptions, topicName, callback);
    }

    static removeEventHandler(topicName, callback) {
      (0, _helpers.removeEventHandler)(this.subscriptions, topicName, callback);
    }

    static notify(topic, ...rest) {
      (0, _helpers.notify)(this.subscriptions, topic, ...rest);
    }

  }

  _exports.default = SharedEventBus;

  _defineProperty(SharedEventBus, "subscriptions", {});
});