define("itarp-admin-dashboard/utils/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventHandler = addEventHandler;
  _exports.removeEventHandler = removeEventHandler;
  _exports.notify = notify;
  _exports.default = void 0;

  function addEventHandler(subscriptions, topicName, callback) {
    subscriptions[topicName] ||= [];
    const topic = subscriptions[topicName];
    const callbackStr = callback.toString();
    const subscriber = topic.find(cb => cb.toString() === callbackStr);
    if (!subscriber) return topic.pushObject(callback);
    topic[topic.indexOf(subscriber)] = callback;
  }

  function removeEventHandler(subscriptions, topic, callback) {
    if (!subscriptions[topic]) return;
    subscriptions[topic] = subscriptions[topic].filter(subscriber => subscriber !== callback);
  }

  function notify(subscriptions, topic, ...rest) {
    const subscribers = subscriptions[topic];
    return subscribers && Promise.all(subscribers.map(cb => cb(...rest)));
  }

  var _default = {
    addEventHandler,
    removeEventHandler,
    notify
  };
  _exports.default = _default;
});